<template>
  <div class="main" style="padding: 0">
    <!-- header -->
    <div class="header" style="z-index: 999">
      <div class="imagebox">
        <img src="../assets/img/ccbst.png" alt="" />
      </div>
      <div @click="getindex" class="titleboxs">返回平台首页</div>
      <div class="titlebox">账号登录</div>
    </div>
    <!-- 内容 -->

    <div class="midelbox">
      <div class="bannerbox">
        <el-carousel :interval="5000" height="360px">
          <el-carousel-item>
            <a href=""
              ><img
                style="display: block; height: 100%; width: 100%"
                src="../assets/img/login_1.jpg"
                alt=""
            /></a>
          </el-carousel-item>

          <el-carousel-item>
            <a href=""
              ><img
                style="display: block; height: 100%; width: 100%"
                src="../assets/img/login_2.jpg"
                alt=""
            /></a>
          </el-carousel-item>

          <el-carousel-item>
            <a href=""
              ><img
                style="display: block; height: 100%; width: 100%"
                src="../assets/img/login_3.jpg"
                alt=""
            /></a>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="login">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          size="min"
          ref="ruleForm"
          class="demo-ruleForm"
           @keyup.enter.native="submitForm('ruleForm')"
        >
          <el-form-item label="用户名：" prop="Account">
            <el-input
              type="text"
              v-model="ruleForm.Account"
              autocomplete="off"
              placeholder="账号 | 手机号"
             
            ></el-input>
          </el-form-item>

          <el-form-item label="密码：" prop="Password">
            <el-input
              type="password"
              v-model="ruleForm.Password"
              placeholder="密码"
              autocomplete="off"
              
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100%"
              type="primary"
              @click="submitForm('ruleForm')"
              
              >登录</el-button
            >
          </el-form-item>
        </el-form>

        <div class="qq">
          还没有账号？ <span @click="goregister">请注册！</span>
        </div>
      </div>
    </div>
    <div class="footboxs">
      <p>
        Copyright © 2021陕西省西安浐灞国际会展有限责任公司. All Rights Reserved <br/>
        
      </p>
      <p><a target="_blank" rel="noopener" href="http://beian.miit.gov.cn/">陕ICP备15005823号-2</a></p>
      <p>联系电话：029-83592139、029-83592506</p>
    </div>
  </div>
</template>


<script>
export default {
  data() {


    return {
      type:0,
      ruleForm: {
        Account: "",
        Password: "",
      },
      rules: {
        Account: [
          {
            required: true,
            min: 2,
            max: 20,
            message: "2 到 20 个字符",
            trigger: "blur",
          },
        ],
        Password: [
          {
            required: true,
            min: 6,
            max: 18,
            message: "6 到 18 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {


 Setcookie (name, value){ 
    //设置名称为name,值为value的Cookie
    var expdate = new Date();   //初始化时间
    expdate.setTime(expdate.getTime() + 1 * 60 * 60 * 1000);   //时间单位毫秒
    document.cookie = name+"="+value+";expires="+expdate.toGMTString()+";path=/";
 
   //即document.cookie= name+"="+value+";path=/";  时间默认为当前会话可以不要，但路径要填写，因为JS的默认路径是当前页，如果不填，此cookie只在当前页面生效！
},

      getindex() {
      this.$router.push("/");
    },
    goregister() {
      this.$router.push("/register");
    },
    submitForm(formName) {
      let that = this;
      let type = that.type
      let form = that.ruleForm;
      if (form.Account.trim().length ==0) {
        that.$message.error("请填写账号或手机号");
        return;
      }
      if (form.Password.trim().length < 6) {
        that.$message.error("请正确填写密码");
        return;
      }
     form.Account= form.Account.trim()
     form.Password=form.Password.trim()
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.$post("api/UserLogin/SignIn", form).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("登录成功");
              window.localStorage.setItem("userId", data.Obj.SysID);
              that.Setcookie("userId",data.Obj.SysID)
              that.ruleForm = {};
              window.localStorage.setItem("Account", data.Obj.Account);
              that.Setcookie("Account",data.Obj.Account)
              that.Setcookie("CompanySysID",data.Obj.CompanySysID)
              window.localStorage.setItem(
                "CompanySysID",
                data.Obj.CompanySysID
              );
              setTimeout(() => {
                if (data.Obj.Type == 1&&type) {
                  that.$router.push({ path: "/companyCenter/centerHome" });
                } else if(data.Obj.Type != 1&&type) {
                  that.$router.push({ path: "/companyCenter/personalCenter" });
                }else{
                  that.$router.go(-1)
                }
              }, 1000);
            } else {
              that.$message.error(data.Msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  mounted(){
    let type = this.$route.query.type
    this.type = type
  }
};
</script>


<style scoped  lang="scss">
.main {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .header {
    position: relative;
    padding-top: 10px;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background-color: #fff;

    box-shadow: 0px 2px 5px #eee;
    z-index: 9999;
    /* border-bottom: 1px solid #eee; */

    .imagebox {
      float: left;
      height: 60px;
      width: 350px;
      margin-left: 30px;

      img {
        height: 60px;
      }
    }
  }
  .login {
    padding: 20px;
    width: 260px;
    margin-left: 50px;
    // background-color: red;
    border: 1px solid #ccc;
  }
}
.titlebox {
  float: left;
  height: 60px;
  line-height: 60px;
  font-weight: 800;
  font-size: 20px;
  margin-left: 200px;
}
    .titleboxs {
    float: left;
    height: 60px;
    line-height: 60px;
    font-size: 12px;
    margin-left: 50px;
    cursor: pointer;
  }
    .titleboxs:hover{
      color: dodgerblue;
    }
.midelbox {
  width: 900px;
  min-height: 600px;
  padding-top: 30px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerbox {
  // height: 300px;
  width: 600px;
}
.qq {
  font-size: 14px;
  margin-bottom: 30px;
  span {
    cursor: pointer;
    color: #409eff;
  }
}
.footboxs {
  height: 200px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 50px;
  box-sizing: border-box;
  background-color: #fff;
  p {
   line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
    text-align: center;
     a{
      color: #666;
    }
    a:hover{
      color: #409EFF;
    }
  }
}
@media (max-width: 500px) {
  .main {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  .header {
    position: relative;
    padding-top: 10px;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background-color: #fff;

    box-shadow: 0px 2px 5px #eee;
    z-index: 9999;
    /* border-bottom: 1px solid #eee; */

    .imagebox {
      float: left;
      height: 60px;
      // width: 350px;
      max-width: 100%;
      margin-left: 20px;
      margin-top: 0;

      img {
        height: 40px;
        margin-top: 10px;
        margin-left: 0;
        width: auto;
      }
    }
  }
  .login {
    padding: 20px;
    width: 100%;
    margin-left: 6px;
    // background-color: red;
    border:none;
  }
}
.titlebox {
  float: left;
  height: 60px;
  line-height: 60px;
  font-weight: 800;
  font-size: 20px;
  margin-left: 50px;
}
    .titleboxs {
    float: left;
    height: 60px;
    line-height: 60px;
    font-size: 12px;
    margin-left: 20px;
    cursor: pointer;
  }
    .titleboxs:hover{
      color: dodgerblue;
    }
.midelbox {
  width: 100%;
  min-height: 200px;
  padding-top: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerbox {
  display: none;
  // height: 300px;
  width: 600px;
}
.qq {
  font-size: 14px;
  margin-bottom: 30px;
  span {
    cursor: pointer;
    color: #409eff;
  }
}
.footboxs {
  position: relative;
  bottom: 0;
  height: 150px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  background-color: #fff;
  p {
   line-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
    text-align: center;
  
    box-sizing: border-box;
  }
}
}
</style>